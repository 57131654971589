// @import "~@angular/material/prebuilt-themes/indigo-pink.css";
@use 'themes' as *;
@use '@nebular/theme/styles/globals' as *;

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
// themes - our custom or/and out of the box themes
@import 'themes';
// framework component themes (styles tied to theme variables)
// @import '~@nebular/theme/styles/globals';
// @import '~@nebular/auth/styles/all';
// @import '~bootstrap/scss/functions';
// @import '~bootstrap/scss/variables';
// @import '~bootstrap/scss/mixins';
// @import '~bootstrap/scss/grid';
// loading progress bar theme
@import './pace.theme';
@import './layout';
@import './overrides';
@import './tables.scss';
// install the framework and custom global styles
@include nb-install() {
    // framework global styles
    @include nb-theme-global();
    // @include nb-auth-global();
    @include ngx-layout();
    // loading progress bar
    @include ngx-pace-theme();
    @include nb-overrides();
}

.swiper-container-cube .swiper-cube-shadow {
    display: none !important;
}

.swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    /* Fix of Webkit flickering */
    z-index: 1;
}

// @import '~swiper/swiper.scss';
// @import '~swiper/components/effect-cube/effect-cube.scss';
// @import '~swiper/components/navigation/navigation.scss';
// swiper.scss - only core Swiper styles
// components/a11y/a11y.scss - styles required for A11y module
// components/controller/controller.scss - styles required for Controller module
// components/effect-coverflow/effect-coverflow.scss - styles required for Coveflow Effect module
// components/effect-cube/effect-cube.scss - styles required for Cube Effect module
// components/effect-fade/effect-fade.scss - styles required for Fade Effect module
// components/effect-flip/effect-flip.scss - styles required for Flip Effect module
// components/lazy/lazy.scss - styles required for Lazy module
// components/navigation/navigation.scss - styles required for Navigation module
// components/pagination/pagination.scss - styles required for Pagination module
// components/scrollbar/scrollbar.scss - styles required for Scrollbar module
// components/thumbs/thumbs.scss - styles required for Thumbs module
// components/zoom/zoom.scss - styles required for Zoom module
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
