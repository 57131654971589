@forward '@nebular/theme/styles/theming';
@use '@nebular/theme/styles/theming' as *;
@use '@nebular/theme/styles/themes/corporate';

html {
    font-size: 14px !important;
}

// @nebular theming framework
// @import '~@nebular/theme/styles/theming';
// @nebular out of the box themes
// @import '~@nebular/theme/styles/themes/corporate';
// $nb-themes: nb-register-theme((
//   layout-padding-top: 2.25rem,
//   menu-item-icon-margin: 0 0.5rem 0 0,
//   card-height-tiny: 13.5rem,
//   card-height-small: 21.1875rem,
//   card-height-medium: 28.875rem,
//   card-height-large: 36.5625rem,
//   card-height-giant: 44.25rem,
//   card-margin-bottom: 1.875rem,
//   card-header-with-select-padding-top: 0.5rem,
//   card-header-with-select-padding-bottom: 0.5rem,
//   select-min-width: 6rem,
//   slide-out-background: #f7f9fc,
//   slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
//   slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
// ), default, default);
// $nb-themes: nb-register-theme((
//   layout-padding-top: 2.25rem,
//   menu-item-icon-margin: 0 0.5rem 0 0,
//   card-height-tiny: 13.5rem,
//   card-height-small: 21.1875rem,
//   card-height-medium: 28.875rem,
//   card-height-large: 36.5625rem,
//   card-height-giant: 44.25rem,
//   card-margin-bottom: 1.875rem,
//   card-header-with-select-padding-top: 0.5rem,
//   card-header-with-select-padding-bottom: 0.5rem,
//   select-min-width: 6rem,
//   slide-out-background: #252547,
//   slide-out-shadow-color: 2px 0 3px #29157a,
//   slide-out-shadow-color-rtl: -2px 0 3px #29157a,
// ), cosmic, cosmic);
$nb-themes: nb-register-theme(( layout-padding-top: 2.25rem, menu-item-icon-margin: 0 0.5rem 0 0, card-height-tiny: 13.5rem, card-height-small: 21.1875rem, card-height-medium: 28.875rem, card-height-large: 36.5625rem, card-height-giant: 44.25rem, card-margin-bottom: 1.875rem, card-header-with-select-padding-top: 0.5rem, card-header-with-select-padding-bottom: 0.5rem, select-min-width: 6rem, slide-out-background: linear-gradient(270deg, #edf1f7 0%, #e4e9f2 100%), slide-out-shadow-color: 0 4px 14px 0 #8f9bb3, slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3, ), default, default);
// $nb-themes: nb-register-theme((
//   layout-padding-top: 2.25rem,
//   menu-item-icon-margin: 0 0.5rem 0 0,
//   card-height-tiny: 13.5rem,
//   card-height-small: 21.1875rem,
//   card-height-medium: 28.875rem,
//   card-height-large: 36.5625rem,
//   card-height-giant: 44.25rem,
//   card-margin-bottom: 1.875rem,
//   card-header-with-select-padding-top: 0.5rem,
//   card-header-with-select-padding-bottom: 0.5rem,
//   select-min-width: 6rem,
//   slide-out-background: linear-gradient(270deg, #222b45 0%, #151a30 100%),
//   slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
//   slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
// ), dark, dark);
//Custome Dark Theme Guest Chat
//$nb-themes: nb-register-theme(( background-basic-color-1: #095570, background-basic-color-3: #F8F8FA), corporate, corporate);
$nb-themes: nb-register-theme(( //sidebar section 
sidebar-background-color: #095570, sidebar-text-color: #ffffff, menu-item-icon-color: #ffffff, menu-text-color: #ffffff, //header section
header-background-color: #095570, header-text-color:#ffffff, actions-icon-color:#ffffff, //popover section
popover-text-color:#ffffff, tooltip-background-color:#5292e9, menu-background-color:#095570, user-name-text-color:#ffffff, user-medium-name-text-font-size: 0.8375rem, user-medium-height:1.85rem, user-medium-width:1.85rem, text-subtitle-2-font-size:.9rem, menu-item-icon-width:1.3rem, menu-text-font-size:0.8rem, actions-small-icon-height:1.0rem, header-height:3.55rem, sidebar-width:13rem, chat-primary-background-color:#7294f5, chat-message-background:#3f86f2, chat-message-reply-background-color:#f2f3f5, sidebar-width-compact:4rem, card-header-primary-background-color:#0c36aa, ), corporate, corporate);