@import "./themes";
@mixin nb-overrides() {

  nb-accordion {
    display: block;
    box-shadow: none;
    border-radius: 0.25rem;
    border: 1px solid #edf1f7;
  }


  nb-accordion-item-header {
    background-color: #095570;
    border-bottom-color: #095570;
    color: #ffffff;
    font-size: 14px;
  }

  .layout {
    background-color: #ffffff;
  }
  .step-content {
    display: none;
  }
  .pageContainer {
    // height: calc(100% - 81px);
    // width: calc(100% - 14.5rem);
    //margin-right: 10px;
    // position: absolute;
    margin-bottom: 0;
    width: 100%;
    // height: 92vh;
    height: calc(100vh - 82px);
  }
  nb-select.size-medium button {
    // padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;
    max-width: 100% !important;
    width: 100%;
    nb-icon {
      right: 0.41rem !important;
    }
  }
  nb-layout-column {
    padding-left: 12px !important;
    padding-top: 12px !important;
    padding-right: 12px !important;
    padding-bottom: 12px !important;
  }
  nb-card {
    // background-color: inherit;
    background-color: #fff;
    border: none;
    &.filter {
      box-shadow: 0 0rem 0.2rem 0 rgba(44, 51, 73, 0.1);
    }
    nb-card-header {
      h4 {
        display: inline;
        line-height: 2.3rem;
      }
      button {
        float: right;
        margin-left: 10px;
      }
    }
  }
  .context-buttons {
    float: right;
    margin-left: 10px;
    &.move-down {
      margin-top: 28px;
    }
  }
  .drag-drop-item {
    cursor: move;
  }
  nb-toggle {
    margin-top: 28px;
  }
  .disabled-heading {
    text-decoration: line-through;
  }
  .crop-buttons {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .buttonSpacing {
      margin-left: 8px;
    }
  }

  .page-container {
    padding: 20px 30px;
    .page-container-content {
      padding: 20px 30px;
    }
  }

  .gcbot {
    fill: #ffffff;
  }
  aside {
    background-color: #095570 !important;
    top: 57px !important;
    height: auto !important;
    footer {
      margin-bottom: 14px;
    }
  }
  .gcbot {
    fill: #ffffff;
  }
  .primitive-overlay {
    color: black !important;
    fill: #ffffff;
    a .gcbot {
      fill: currentColor;
    }
    .profile-sidebar.end {
      //-webkit-transform: translate3d(-100%, 0, 0);
      //transform: translate3d(-100%, 0, 0);
      transform: translateX(-100%);
      transition: 500ms ease-in-out;
      &.expanded {
        //-webkit-transform: translate3d(0, 0, 0);
        //transform: translate3d(0, 0, 0);
        transform: translateX(0);
      }
    }
  }
  .route-tabset {
    padding-left: 0px;
    max-width: 80%;
    display: inline-flex !important;
  }

  nb-sidebar {
    width: calc(13 * 15px); //13rem
    &.expanded {
      width: calc(13 * 15px); //13rem
    }
    .main-container {
      width: calc(13 * 15px); //13rem
    }
    .scrollable {
      padding: 0px;
    }
  }
  .logo-container {
    width: calc(13 * 15px - 1.25rem) !important;
  }

  .nb-theme-corporate [nbButton].appearance-filled.status-primary,
  .nb-theme-corporate .appearance-filled.status-primary[nbButtonToggle] {
    background-color: #095570 !important;
    border-color: #095570 !important;
    color: #ffffff !important;
  }

  .select-button {
    min-width: 0px !important;
    width: 100% !important;
  }

  .room-wrapper-tabset .route-tabset {
    margin-bottom: 30px;
  }

  .i-style {
    font-size: 16px !important;
    margin-right: 5px;
  }

  nb-checkbox {
    display: block;
    margin-bottom: 5px;
  }

  .top-bottom-space {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .white-top-space {
    margin-top: 20px;
  }

  .delete-schedule {
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    float: right;
  }

  .label {
    color: black;
    display: block;
    font-size: 14px !important;
    font-weight: bold !important;
  }

  .vertical-middle {
    margin: 0 !important;
    position: absolute !important;
    top: 50% !important;
    -ms-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
  }

  .top-10-margin {
    margin-top: 10px;
  }

  .link-btn {
    cursor: pointer;
    color: #095570 !important;
    margin-top: 40px;
    font-size: 14px;
    font-weight: bold;
  }

  .link-btn:action {
    color: black !important;
  }

  .link-btn:hover {
    color: rgba(51, 102, 255, 1) !important;
  }

  .pointer-events-none {
    pointer-events: none;
    color: #f7f9fc;
    display: none;
  }

  .navigation-buttons {
    display: flex;
    justify-content: center;
  }

  td {
    padding-right: 10px;
    // padding-left: 10px;
  }

  select:not([multiple]) {
    font-family: "Open Sans", sans-serif;
    height: 36px;
    width: 200px;
    border-radius: 0.17rem;
    font-size: 0.9375rem;
    font-weight: 600;
    padding: 0.4375rem 1rem;
    background-color: #f7f9fc;
    border-color: #e4e9f2;
    color: #222b45;
  }

  select[multiple] {
    height: auto;
    font-family: "Open Sans", sans-serif;
    width: 200px;
    border-radius: 0.17rem;
    font-size: 0.9375rem;
    font-weight: 600;
    padding: 0.4375rem 1rem;
    background-color: #f7f9fc;
    border-color: #e4e9f2;
    color: #222b45;
  }

  select:focus {
    box-shadow: 0 0 3pt 2pt #719ece;
  }

  .navigation-buttons-right {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .navigation-buttons-left {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
  }

  input[type="number"],
  nb-select {
    width: 115px;
    height: 36px;
  }

  input[type="text"],
  input[type="email"],
  input[type="date"],
  input[type="tel"] {
    width: 200px;
    height: 36px;
  }

  .icon-container-2 {
    display: inline-block;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    transition: rotate 300ms ease-in;
    &.collapsed {
      transform: rotateX(180deg);
    }
    .disabled {
      pointer-events: none;

      /* for "disabled" effect */
      opacity: 0.5;
      color: #ccc;
    }
    nb-icon {
      color: black;
      font-size: 20px;
      position: relative;
      top: calc(50% - 10px);
      cursor: pointer;
    }
  }

  .nb-overlay-bottom-starter {
    border: none;
  }

  .header-right {
    float: right;
  }

  .outer-form-array-div {
    display: inline-block;
    margin: 6px;
    h6 {
      margin-bottom: 4px;
    }
    .form-array-item {
      display: inline-block;
      background-color: #f2f5ff;
      padding: 16px;
      border: 1px solid rgb(211, 211, 211);
      border-radius: 4px;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
      margin-bottom: 12px;
      .pos-bottom {
        position: absolute;
        bottom: 0;
      }
    }
  }

  .tab-link {
    height: 100%;
  }

  @media only screen and (min-width: 768px) {
    .pos-bottom {
      position: absolute;
      bottom: 0;
    }
  }

  .scrollable {
    overflow-y: auto;
  }

  .icon-text-right-margin nb-icon {
    margin-right: 6px;
  }

  input[type="number"] {
    max-width: 220px !important;
  }

  .payment-div .outer-circle,
  .payment-div .inner-circle {
    left: calc(50% - 17.5px) !important;
  }

  .a-toggle {
    margin-right: 20px;
    display: flex;
    align-self: center;
    font-size: 20px;
    cursor: pointer;
    opacity: 0.7;
  }
  .a-toggle:hover {
    margin-right: 20px;
    display: flex;
    align-self: center;
    font-size: 20px;
    cursor: pointer;
    opacity: 1;
  }
}

.cdk-drag-preview {
  img {
    height: 100%;
    width: 100%;
    // object-fit: contain;
  }
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%; // you can add in px also
  height: 100%; // this line added
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.angular-editor-textarea {
  height: 160px !important;
}

span.is-empty::after {
  content: "Review";
  color: red;

  position: absolute;
  top: 0;
  left: 0;
  font-size: 10px;
  font-weight: bold;
  height: 20px;
  // border: 1px solid red!important; /* Red border only if the input is empty */
}

span.is-empty-array::after {
  content: "Review if applicable";
  color: red;

  position: absolute;
  top: 0;
  left: 0;
  font-size: 10px;
  font-weight: bold;
  height: 20px;
  // border: 1px solid red!important; /* Red border only if the input is empty */
}

.red-note-alert {
  color: #bb0930 !important;
  font-weight: bold;
}

.section-actions {
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  .flag-action {
    color: #fff;
    cursor: pointer;
  }
  .note-action {
    position: absolute;
    right: 20px;
    bottom: 0px;
    color: #fff;
    cursor: pointer;
  }
}

.check-start .label {
  cursor: pointer;
}

.hide-overflow-y .ngx-datatable.scroll-horz .datatable-body {
  overflow-y: hidden;
}

.mat-paginator-container {
  margin-top: 12px;
  justify-content: flex-start !important;
}

// theses color corrisponds to the primary color
.nb-theme-corporate
  nb-radio.status-basic
  .native-input:enabled:checked
  ~ .inner-circle,
.nb-theme-corporate [nbButton].appearance-filled.status-primary,
.nb-theme-corporate .appearance-filled.status-primary[nbButtonToggle],
.nb-theme-corporate nb-checkbox.status-basic .custom-checkbox.checked,
.nb-theme-corporate nb-tag.appearance-filled.status-primary,
.nb-theme-corporate nb-route-tabset .route-tab.active .tab-link::before,
.ngx-datatable .datatable-footer,
.nb-theme-corporate nb-tabset .tab.active .tab-link::before {
  background-color: #095570 !important;
}

.nb-theme-corporate nb-checkbox.status-basic .custom-checkbox.checked,
.nb-theme-corporate nb-tag.appearance-filled.status-primary,
.nb-theme-corporate nb-tag.appearance-outline.status-primary,
.nb-theme-corporate [nbButton].appearance-outline.status-primary,
.nb-theme-corporate .appearance-outline.status-primary[nbButtonToggle] {
  border-color: #095570 !important;
}

.nb-theme-corporate nb-tag.appearance-outline.status-primary,
.nb-theme-corporate [nbButton].appearance-ghost.status-primary,
.nb-theme-corporate .appearance-ghost.status-primary[nbButtonToggle],
.nb-theme-corporate [nbButton].appearance-outline.status-primary,
.nb-theme-corporate .appearance-outline.status-primary[nbButtonToggle],
.nb-theme-corporate nb-route-tabset .route-tab.active .tab-link,
.ice-action,
.nb-theme-corporate nb-tabset .tab.active .tab-link {
  color: #095570 !important;
}

.nb-theme-corporate nb-spinner.status-basic {
  background-color: #fff !important;
}

.nb-theme-corporate a:hover {
  color: #15aaaf !important;
  border-color: #15aaaf !important;
}

.nb-theme-corporate
  .note-value
  nb-tabset
  .tabset
  li:last-child
  .tab-link
  .tab-text {
  color: rgb(255, 0, 0) !important;
}

.suvey-header {
  background-color: #55c8f1;
  border-radius: 4px;
  min-height: 50px;
  width: 100%;
  display: block;
  padding: 6px 20px;
  margin-bottom: 20px;
  h5 {
    margin: 0px;
  }
  .survey-header-title {
    
    .left-section {
      display: inline-block;
      width: calc(100% - 50px);
      height: 55px;
      // line-height: 50px;
      line-height: 20px;
      nb-checkbox {
        margin-bottom: 0px;
        display: inline-block;
        height: 20px;
        width: 26px;
        margin-top: 10px;
      }
      .item-text {
        margin-right: 14px;
        vertical-align: top;
        margin-top: 9px;
        display: inline-block;
        font-size: 18px;
        font-weight: 600;
        cursor:pointer;
        width: calc(100% - 50px);
      }
      .flag-action {
        color: #fff;
        cursor: pointer;
        margin-bottom: 0px;
        width: fit-content;
      }
    }
    .icon-container {
      // height: 24px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 50px;
      cursor: pointer;
      transition: rotate 300ms ease-in;
      float: right;
      height: 50px;
      &.collapsed {
        transform: rotateX(180deg);
      }
      // nb-icon {
      //     position: absolute;
      //     top: 50%;
      //     right: 30px;
      // }
    }
    .icons {
      display: inline-block;
    }
  }
  .suvey-header-actions {
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    border-radius: 4px;

    .flag-action {
      color: #fff;
      cursor: pointer;
    }
    .note-action {
      position: absolute;
      right: 0;
      bottom:-2px;
      color: #fff;
      cursor: pointer;
    }
    .open-box {
      position: absolute;
      top: 10px;
      right: 0px;
      min-height: 70px;
      width: 200px;
      z-index: 100;
      background-color: #55c8f1;
      padding: 6px 6px 18px 6px;
      box-shadow: 5px 5px 10px grey;
    }
  }
}
